import "./App.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import TermsOfUse from "./pages/termsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import CriticalIncident from "./pages/criticalIncident/CriticalIncident";
import LoadingBar from "react-top-loading-bar";

function App() {
  const [progress, setProgress] = useState();

  return (
    <div className="App">
      <LoadingBar color="#007bff" progress={progress} />
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            component={(props) => (
              <Home
                setProgress={setProgress}
                {...props}
                title="Software Algo Solutions"
              />
            )}
          />
          <Route
            path="/termsOfUse"
            component={(props) => (
              <TermsOfUse
                setProgress={setProgress}
                {...props}
                title="Terms of Use | Software Algo Solutions"
              />
            )}
          />
          <Route
            path="/privacyPolicy"
            component={(props) => (
              <PrivacyPolicy
                setProgress={setProgress}
                {...props}
                title="Privacy Policy | Software Algo Solutions"
              />
            )}
          />
          <Route
            path="/criticalIncident"
            component={(props) => (
              <CriticalIncident
                setProgress={setProgress}
                {...props}
                title="Critical Incident | Software Algo Solutions"
              />
            )}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
