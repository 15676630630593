import React, { useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { compliance, taxation, clearing, client, clientMobile, ria, metabase } from '../../../../constants/sas_links';

function Portfolio(props) {
  // Portfolio Section Screen
  const [isOnPortfolioScreen, setIsOnPortfolioScreen] = useState(false);
  const [p1Class, setP1Class] = useState('');
  const [p2Class, setP2Class] = useState('');
  const [p3Class, setP3Class] = useState('');
  const [p4Class, setP4Class] = useState('');
  const [p5Class, setP5Class] = useState('');
  const [p6Class, setP6Class] = useState('');
  const [p7Class, setP7Class] = useState('');
  const [prsClass, setPrsClass] = useState('');

  const handleWayPointEnterPortfolioSection = () => {
    setIsOnPortfolioScreen(true);
    if (!isPortfolioClicked) {
      setTimeout(() => {
        setP1Class('animateSlideUp');
      }, 0);
      setTimeout(() => {
        setP2Class('animateSlideUp');
      }, 200);
      setTimeout(() => {
        setP3Class('animateSlideUp');
      }, 400);
      setTimeout(() => {
        setP4Class('animateSlideUp');
      }, 600);
      setTimeout(() => {
        setP5Class('animateSlideUp');
      }, 800);
      setTimeout(() => {
        setP6Class('animateSlideUp');
      }, 1000);
      setTimeout(() => {
        setP7Class('animateSlideUp');
      }, 1200);
    }
  };

  // Handling Portfolio Button
  const [isPortfolioClicked, setIsPortfolioClicked] = useState(false);
  const [pHeadingClass, setPHeadingClass] = useState('');
  const [selectedTab, setSelectedTab] = useState('');

  const handlePortfolioClickAnimation = () => {
    if (!isPortfolioClicked) {
      setTimeout(() => {
        setPHeadingClass(' animateSlideLeft');
      }, 0);
      setTimeout(() => {
        setP1Class(p1Class + ' animateSlideLeft');
      }, 200);
      setTimeout(() => {
        setP2Class(p2Class + ' animateSlideLeft');
      }, 400);
      setTimeout(() => {
        setP3Class(p3Class + ' animateSlideLeft');
      }, 600);
      setTimeout(() => {
        setP4Class(p4Class + ' animateSlideLeft');
      }, 800);
      setTimeout(() => {
        setP5Class(p5Class + ' animateSlideLeft');
      }, 1000);
      setTimeout(() => {
        setP6Class(p6Class + ' animateSlideLeft');
      }, 1200);
      setTimeout(() => {
        setP7Class(p7Class + ' animateSlideLeft');
      }, 1400);
      setTimeout(function () {
        setIsPortfolioClicked(true);
        setPrsClass('animateSlideUp');
      }, 1800);
    }
  };

  // Handling Portfolio Button Content
  const [p1ContentClass, setP1ContentClass] = useState('content-item inactive');
  const [p2ContentClass, setP2ContentClass] = useState('content-item inactive');
  const [p3ContentClass, setP3ContentClass] = useState('content-item inactive');
  const [p4ContentClass, setP4ContentClass] = useState('content-item inactive');
  const [p5ContentClass, setP5ContentClass] = useState('content-item inactive');
  const [p6ContentClass, setP6ContentClass] = useState('content-item inactive');
  const [p7ContentClass, setP7ContentClass] = useState('content-item inactive');

  useEffect(() => {
    if (selectedTab === 1) {
      setP1ContentClass('content-item active');
    } else {
      setP1ContentClass('content-item inactive');
    }

    if (selectedTab === 2) {
      setP2ContentClass('content-item active');
    } else {
      setP2ContentClass('content-item inactive');
    }

    if (selectedTab === 3) {
      setP3ContentClass('content-item active');
    } else {
      setP3ContentClass('content-item inactive');
    }

    if (selectedTab === 4) {
      setP4ContentClass('content-item active');
    } else {
      setP4ContentClass('content-item inactive');
    }

    if (selectedTab === 5) {
      setP5ContentClass('content-item active');
    } else {
      setP5ContentClass('content-item inactive');
    }

    if (selectedTab === 6) {
      setP6ContentClass('content-item active');
    } else {
      setP6ContentClass('content-item inactive');
    }

    if (selectedTab === 7) {
      setP7ContentClass('content-item active');
    } else {
      setP7ContentClass('content-item inactive');
    }
  }, [selectedTab]);

  return (
    <div ref={props.thisRef}>
      {/* <!-- Portfolio Section --> */}
      <Waypoint onEnter={handleWayPointEnterPortfolioSection}>
        <div id="portfolio-section" className="portfolio-content">
          <div className="grd-row portfolio-container grd-align-center">
            <div id="p-l-side" className="sidebar grd-cell-none">
              <h2 id="p-headingt" className={'text-center set-position ' + pHeadingClass}>
                Portfolio
              </h2>
              <ul id="p-list" className="side-menu">
                <li id="p1" className={isOnPortfolioScreen ? 'set-position ' + p1Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(1);
                    }}
                  >
                    Equity, Option and Digital Asset Clearing System
                  </a>
                </li>
                <li id="p2" className={isOnPortfolioScreen ? 'set-position ' + p2Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(2);
                    }}
                  >
                    Client Site
                  </a>
                </li>
                <li id="p3" className={isOnPortfolioScreen ? 'set-position ' + p3Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(3);
                    }}
                  >
                    Client Mobile App
                  </a>
                </li>
                <li id="p4" className={isOnPortfolioScreen ? 'set-position ' + p4Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(4);
                    }}
                  >
                    Tax Processing System
                  </a>
                </li>
                <li id="p5" className={isOnPortfolioScreen ? 'set-position ' + p5Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(5);
                    }}
                  >
                    Compliance System
                  </a>
                </li>
                <li id="p6" className={isOnPortfolioScreen ? 'set-position ' + p6Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(6);
                    }}
                  >
                    RIA App
                  </a>
                </li>
                <li id="p7" className={isOnPortfolioScreen ? 'set-position ' + p7Class : 'set-position'}>
                  <a
                    onClick={() => {
                      handlePortfolioClickAnimation();
                      setSelectedTab(7);
                    }}
                  >
                    Adhoc Reporting
                  </a>
                </li>
              </ul>
            </div>
            <div id="p-r-side" className="grd-cell">
              <div id="p-r-side-c" className={isPortfolioClicked ? 'tab-contents ' + prsClass : 'tab-contents hidden'}>
                <div className={p1ContentClass}>
                  <h3>Equity, Option and Digital Asset Clearing System</h3>
                  <div className="content">
                    <p>
                      An extensive and dynamic clearing and processing system for Equities, Options and Digital Assets.
                      Real time trade and clearing process allows for up to the minute position, balance, P&L and margin
                      calculations. Real-time clearing connectivity allows for instant view of trade and position
                      reconciliation with NSCC/DTCC.
                    </p>
                    <a className="sas-link" href={clearing} target="_blank">
                      <i className="fas fa-globe-americas"></i> Visit Website
                    </a>

                    <label className="sub-header">Features:</label>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>Realtime NSCC and CNS reconciliation</li>
                          <li>Realtime Position, Balance and activity</li>
                          <li>Fully integrated NSCC/DTCC/CNS services including MQ and FIX protocols</li>
                          <li>Complete OMS integration including Rest API, FIX and gRPC</li>
                          <li>Automated account creation/maintenance via Rest API and gRPC</li>
                          <li>Corporate Action</li>
                          <li>Security and Price Master</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>Stock Loan</li>
                          <li>Physical Certificate</li>
                          <li>BlueSheet</li>
                          <li>OATS</li>
                          <li>15c3</li>
                          <li>CAT</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={p2ContentClass}>
                  <h3>Client Site</h3>
                  <div className="content">
                    <p>
                      A web-based white labeled application that can be accessed directly by the clients. Fully
                      integrated with automated AML/KYC functionality and all required data capture to automate the
                      account opening process. Client site includes the following:
                    </p>
                    <a className="sas-link" href={client} target="_blank">
                      <i className="fas fa-globe-americas"></i> Visit Website
                    </a>

                    <label className="sub-header">Features:</label>
                    <ul>
                      <li>Account Setup</li>
                      <li>Bank Linkage via PLAID</li>
                      <li>ACH/Wire request</li>
                      <li>Tax Filing reports</li>
                      <li>Balance, Position, Activity, Margin Requirement reports</li>
                      <li>Trade Confirm and Monthly Statements</li>
                      <li>Invoices</li>
                    </ul>
                  </div>
                </div>
                <div className={p3ContentClass}>
                  <h3>Client Mobile App</h3>
                  <div className="content">
                    <p>
                      A mobile application version of the Broker Dealer Client site which can be accessed by the client
                      anytime, anywhere. The application is supported by iOS, Android and Windows systems.
                    </p>
                    <a className="sas-link" href={clientMobile} target="_blank">
                      <i className="fas fa-globe-americas"></i> Visit Website
                    </a>

                    <label className="sub-header">Features:</label>
                    <ul>
                      <li>Position</li>
                      <li>Balance</li>
                      <li>Activity</li>
                      <li>Margin Calls</li>
                      <li>ACH/Wire Request</li>
                    </ul>
                  </div>
                </div>
                <div className={p4ContentClass}>
                  <h3>Tax Processing System</h3>
                  <div className="content">
                    <p>
                      A software created to automate tax processing, IRS submissions, and other tax-related activities.
                      It is built to provide tax-time priorities notification and tax preparations.
                    </p>
                    <a className="sas-link" href={taxation} target="_blank">
                      <i className="fas fa-globe-americas"></i> Visit Website
                    </a>

                    <label className="sub-header">Features:</label>
                    <ul>
                      <li>Automated processing, reporting and IRS submission of 1042 and 1099</li>
                      <li>Notifications from IRS and state upon processing of returns.</li>
                      <li>Tracking of IRS refund status</li>
                      <li>Electronic Income Tax Return and Electronic Deferred Tax application</li>
                      <li>
                        Automated calculations and generation of report for Value Added Tax for income, expenses and
                        interest
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={p5ContentClass}>
                  <h3>Compliance System</h3>
                  <div className="content">
                    <p>
                      An application that provides a compliance technology platform for financial services. The
                      functionality ranges from compliance risk monitoring, compliance tracking, reporting, updating
                      compliance content and analytics.
                    </p>
                    <a className="sas-link" href={compliance} target="_blank">
                      <i className="fas fa-globe-americas"></i> Visit Website
                    </a>

                    <label className="sub-header">Features:</label>
                    <div className="row">
                      <div className="col-md-4">
                        <ul>
                          <li>Surveillance set up and report</li>
                          <li>Compliance Task Monitor System</li>
                          <li>Exception Reports</li>
                          <li>Insider Trading</li>
                          <li>Layering</li>
                          <li>Marking the Close</li>
                          <li>Mini Manipulation</li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          <li>MOC-LOC Post</li>
                          <li>Odd Lot</li>
                          <li>Short Sale Violation</li>
                          <li>Trade Participation Report</li>
                          <li>UnDue Concentration</li>
                          <li>VCR</li>
                          <li>Wash Sale and etc.</li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          <li>Margin Monitoring System</li>
                          <li>Margin BP usage stats</li>
                          <li>Margin Requirement</li>
                          <li>Day Trade Buying Power</li>
                          <li>Time and Tick</li>
                          <li>And more...</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={p6ContentClass}>
                  <h3>RIA App</h3>
                  <div className="content">
                    <p>
                      The platform offers connections to multiple custodians (Fidelity and Schwab), other RIA tools, and
                      advisor portals providing SSO logins, so all your data is centralized in one application. All data
                      is gathered and reported in one site eliminating the need to log into and compile reports from
                      multiple providers. The platform also offers portfolio balancing, asset distribution, and variance
                      calculations based on pre-defined risk tolerance.
                    </p>
                    <a className="sas-link" href={ria} target="_blank">
                      Go to Website
                    </a>
                    <label className="sub-header">Features:</label>
                    <div className="row">
                      <div className="col-md-4">
                        <ul>
                          <li>Billing</li>
                          <li>Product Generator</li>
                          <li>Dynamic Reports</li>
                          <li>Capital Variance Model</li>
                          <li>Exception Reports</li>
                          <li>Trading Platform</li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          <li>Position</li>
                          <li>Balance</li>
                          <li>Transaction</li>
                          <li>RMD Reports</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={p7ContentClass}>
                  <h3>Adhoc Reporting</h3>
                  <div className="content">
                    <p>
                      Reporting provides the capabilities to generate on-demand queries and scheduled reports with data
                      explicitly tailored to the user’s needs directly from the SAS database. Queries can either be
                      written directly using T-SQL or built using the predefined interactive dashboards for
                      non-technical users. This allows staff to get targeted answers to their questions and the ability
                      to create custom dashboards and reports. This opens up the SAS data to users of all technical
                      levels and gives the ability to make the data yours.
                    </p>
                    <a className="sas-link" href={metabase} target="_blank">
                      Go to Website
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
    </div>
  );
}

export default Portfolio;
