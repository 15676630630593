// source: proto/admpb/contactus.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */

/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.admpb.ContactUs', null, global);
goog.exportSymbol('proto.admpb.EmptyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ContactUs = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ContactUs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ContactUs.displayName = 'proto.admpb.ContactUs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.EmptyResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.EmptyResponse.displayName = 'proto.admpb.EmptyResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.admpb.ContactUs.prototype.toObject = function (opt_includeInstance) {
    return proto.admpb.ContactUs.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.admpb.ContactUs} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.admpb.ContactUs.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        contactUsId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        email: jspb.Message.getFieldWithDefault(msg, 3, ''),
        phone: jspb.Message.getFieldWithDefault(msg, 4, ''),
        companyName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        country: jspb.Message.getFieldWithDefault(msg, 6, ''),
        note: jspb.Message.getFieldWithDefault(msg, 7, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ContactUs}
 */
proto.admpb.ContactUs.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ContactUs();
  return proto.admpb.ContactUs.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ContactUs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ContactUs}
 */
proto.admpb.ContactUs.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setContactUsId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setNote(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ContactUs.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ContactUs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ContactUs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ContactUs.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getContactUsId();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
};

/**
 * optional uint32 contact_us_id = 1;
 * @return {number}
 */
proto.admpb.ContactUs.prototype.getContactUsId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setContactUsId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.admpb.ContactUs.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string email = 3;
 * @return {string}
 */
proto.admpb.ContactUs.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string phone = 4;
 * @return {string}
 */
proto.admpb.ContactUs.prototype.getPhone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setPhone = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string company_name = 5;
 * @return {string}
 */
proto.admpb.ContactUs.prototype.getCompanyName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setCompanyName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string country = 6;
 * @return {string}
 */
proto.admpb.ContactUs.prototype.getCountry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setCountry = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string note = 7;
 * @return {string}
 */
proto.admpb.ContactUs.prototype.getNote = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.admpb.ContactUs} returns this
 */
proto.admpb.ContactUs.prototype.setNote = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.admpb.EmptyResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.admpb.EmptyResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.admpb.EmptyResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.admpb.EmptyResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.EmptyResponse}
 */
proto.admpb.EmptyResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.EmptyResponse();
  return proto.admpb.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.EmptyResponse}
 */
proto.admpb.EmptyResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.EmptyResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.admpb.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.EmptyResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

goog.object.extend(exports, proto.admpb);
