import {
  CriticalIncidentServiceClient,
  CriticalIncident,
} from "../proto/commonpb/criticalincident_grpc_web_pb";

// import { auth } from "../lib/auth/Auth";

const service = new CriticalIncidentServiceClient(window.env.GRPC_ENDPOINT, {});

export async function createCriticalIncident(param) {
  return new Promise((resolve, reject) => {
    const req = new CriticalIncident();
    req.setIssueType(param.issueType);
    req.setAccountOwnerName(param.accountOwnerName);
    req.setAccountNo(param.accountNo);
    req.setIssue(param.issue);
    req.setContactName(param.contactName);
    req.setContactNo(param.contactNo);
    req.setContactEmail(param.contactEmail);
    req.setContactRole(param.contactRole);
    req.setAdminAccess(param.adminAccess === "Yes" ? true : false);
    req.setPreferredContact(param.preferredContact);

    console.log(param);
    service.createCriticalIncident(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
    
  });
}
