import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

function NewsAndUpdates(props) {
  // News and Updates Header Section Screen
  const [isOnNewsAndUpdatesHeaderScreen, setIsOnNewsAndUpdatesHeaderScreen] = useState(false);
  const [nHeadingClass, setNHeadingClass] = useState('');

  const handleWayPointEnterNewsAndUpdatesHeaderSection = () => {
    setIsOnNewsAndUpdatesHeaderScreen(true);
    setTimeout(() => {
      setNHeadingClass('animateSlideUp');
    }, 0);
  };

  // News and Updates Cards Section Screen
  const [isOnNewsAndUpdatesCardsScreen, setIsOnNewsAndUpdatesCardsScreen] = useState(false);
  const [cryptoClass, setCryptoClass] = useState('');
  const [managingServicesClass, setManagingServicesClass] = useState('');
  const [soc2Class, setSoc2Class] = useState('');

  const handleWayPointEnterNewsAndUpdatesCardsSection = () => {
    setIsOnNewsAndUpdatesCardsScreen(true);
    setTimeout(() => {
      setCryptoClass('animateSlideUp');
    }, 0);
    setTimeout(() => {
      setManagingServicesClass('animateSlideUp');
    }, 300);
    setTimeout(() => {
      setSoc2Class('animateSlideUp');
    }, 600);
  };

  return (
    <div ref={props.thisRef}>
      {/* <!-- News and Updates Section --> */}
      <div id="news-and-updates-section" className="news-and-updates-content">
        <Waypoint onEnter={handleWayPointEnterNewsAndUpdatesHeaderSection}>
          <h2
            id="n-heading"
            className={
              isOnNewsAndUpdatesHeaderScreen
                ? 'news-and-updates-title text-center ' + nHeadingClass
                : 'news-and-updates-title text-center '
            }
          >
            News and Updates
          </h2>
        </Waypoint>

        <Waypoint onEnter={handleWayPointEnterNewsAndUpdatesCardsSection}>
          <div id="cards_landscape_wrap-2" className="news-and-updates-cards">
            <div className="container">
              <div className="row">
                <div
                  className={
                    isOnNewsAndUpdatesCardsScreen
                      ? 'col-xs-12 col-sm-12 col-md-4 col-lg-4 nu-card ' + cryptoClass
                      : 'col-xs-12 col-sm-12 col-md-4 col-lg-4 nu-card'
                  }
                  id="crypto"
                >
                  <div className="card-flyer">
                    <div className="text-box">
                      <div className="image-box">
                        <img src="/css/images/crypto.jpg" alt="Crypto" />
                      </div>
                      <div className="text-container">
                        <h6>Crypto</h6>
                        <p>
                          Software Algo Solutions is excited to be a technology partner for developing a comprehensive
                          crypto trading platform for a private US bank to trade their stable coin and utilize crypto as
                          a form of payment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isOnNewsAndUpdatesCardsScreen
                      ? 'col-xs-12 col-sm-12 col-md-4 col-lg-4 nu-card ' + managingServicesClass
                      : 'col-xs-12 col-sm-12 col-md-4 col-lg-4 nu-card'
                  }
                  id="managingservices"
                >
                  <div className="card-flyer">
                    <div className="text-box">
                      <div className="image-box">
                        <img src="/css/images/manageservices.jpg" alt="Manage Services" />
                      </div>
                      <div className="text-container">
                        <h6>Managing Services</h6>
                        <p>
                          SAS now offers both Technical and Operational Support for the Clearing Application. We ensure
                          efficient and effective support for our client's needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isOnNewsAndUpdatesCardsScreen
                      ? 'col-xs-12 col-sm-12 col-md-4 col-lg-4 nu-card ' + soc2Class
                      : 'col-xs-12 col-sm-12 col-md-4 col-lg-4 nu-card'
                  }
                  id="soc2"
                >
                  <div className="card-flyer">
                    <div className="text-box">
                      <div className="image-box">
                        <img src="/css/images/soc-2-compliance.png" alt="SOC2 Compliance" />
                      </div>

                      <div className="text-container">
                        <h6>SOC2 Compliance</h6>
                        <p>
                          As part of our continuous desire to improve our services, Software Algo Solutions is currently
                          in the process of our SOC2 compliance. This will assure our clients of secure and risk-free
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Waypoint>
      </div>
    </div>
  );
}

export default NewsAndUpdates;
