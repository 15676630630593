// source: proto/commonpb/criticalincident.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.commonpb.CreateCriticalIncidentResponse', null, global);
goog.exportSymbol('proto.commonpb.CriticalIncident', null, global);
goog.exportSymbol('proto.commonpb.EmptyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.CriticalIncident = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.CriticalIncident, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.CriticalIncident.displayName = 'proto.commonpb.CriticalIncident';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.EmptyResponse.displayName = 'proto.commonpb.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.CreateCriticalIncidentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.CreateCriticalIncidentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.CreateCriticalIncidentResponse.displayName = 'proto.commonpb.CreateCriticalIncidentResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.CriticalIncident.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.CriticalIncident.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.CriticalIncident} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.CriticalIncident.toObject = function(includeInstance, msg) {
  var f, obj = {
    issueType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountOwnerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    issue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contactRole: jspb.Message.getFieldWithDefault(msg, 8, ""),
    adminAccess: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    preferredContact: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    msg: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.CriticalIncident}
 */
proto.commonpb.CriticalIncident.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.CriticalIncident;
  return proto.commonpb.CriticalIncident.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.CriticalIncident} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.CriticalIncident}
 */
proto.commonpb.CriticalIncident.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountOwnerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactRole(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdminAccess(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredContact(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.CriticalIncident.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.CriticalIncident.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.CriticalIncident} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.CriticalIncident.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssueType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountOwnerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIssue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContactRole();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdminAccess();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPreferredContact();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string issue_type = 1;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getIssueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setIssueType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_owner_name = 2;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getAccountOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setAccountOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string issue = 4;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getIssue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setIssue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_name = 5;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contact_no = 6;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getContactNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setContactNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact_email = 7;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string contact_role = 8;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getContactRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setContactRole = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool admin_access = 9;
 * @return {boolean}
 */
proto.commonpb.CriticalIncident.prototype.getAdminAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setAdminAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string preferred_contact = 10;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getPreferredContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setPreferredContact = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string msg = 12;
 * @return {string}
 */
proto.commonpb.CriticalIncident.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.CriticalIncident} returns this
 */
proto.commonpb.CriticalIncident.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.EmptyResponse}
 */
proto.commonpb.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.EmptyResponse;
  return proto.commonpb.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.EmptyResponse}
 */
proto.commonpb.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.CreateCriticalIncidentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.CreateCriticalIncidentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.CreateCriticalIncidentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.CreateCriticalIncidentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    criticalIncident: (f = msg.getCriticalIncident()) && proto.commonpb.CriticalIncident.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.CreateCriticalIncidentResponse}
 */
proto.commonpb.CreateCriticalIncidentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.CreateCriticalIncidentResponse;
  return proto.commonpb.CreateCriticalIncidentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.CreateCriticalIncidentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.CreateCriticalIncidentResponse}
 */
proto.commonpb.CreateCriticalIncidentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commonpb.CriticalIncident;
      reader.readMessage(value,proto.commonpb.CriticalIncident.deserializeBinaryFromReader);
      msg.setCriticalIncident(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.CreateCriticalIncidentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.CreateCriticalIncidentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.CreateCriticalIncidentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.CreateCriticalIncidentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriticalIncident();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commonpb.CriticalIncident.serializeBinaryToWriter
    );
  }
};


/**
 * optional CriticalIncident critical_incident = 1;
 * @return {?proto.commonpb.CriticalIncident}
 */
proto.commonpb.CreateCriticalIncidentResponse.prototype.getCriticalIncident = function() {
  return /** @type{?proto.commonpb.CriticalIncident} */ (
    jspb.Message.getWrapperField(this, proto.commonpb.CriticalIncident, 1));
};


/**
 * @param {?proto.commonpb.CriticalIncident|undefined} value
 * @return {!proto.commonpb.CreateCriticalIncidentResponse} returns this
*/
proto.commonpb.CreateCriticalIncidentResponse.prototype.setCriticalIncident = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commonpb.CreateCriticalIncidentResponse} returns this
 */
proto.commonpb.CreateCriticalIncidentResponse.prototype.clearCriticalIncident = function() {
  return this.setCriticalIncident(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commonpb.CreateCriticalIncidentResponse.prototype.hasCriticalIncident = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.commonpb);
