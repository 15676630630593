import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { createCriticalIncident } from "../../services/CriticalIncidentService";
function CriticalIncident(props) {
  useEffect(() => {
    props.setProgress(100);
    document.title = props.title || "";
    // eslint-disable-next-line
  }, []);

  const [criticalIncident, setCriticalIncident] = useState({
    issueType: "System Outage",
    accountOwnerName: "",
    accountNo: "",
    contactName: "",
    contactRole: "",
    contactEmail: "",
    contactNo: "",
    preferredContact: "",
    issue: "",
    adminAccess: "Yes",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [isMessageSent, setIsMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCriticalIncident((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleContactNoChange = (value) => {
    setCriticalIncident((prev) => ({
      ...prev,
      contactNo: value,
    }));
  };

  const handleSubmit = async () => {
    if (!criticalIncident.issueType) {
      setErrorMessage("Issue Type is required.");
      return;
    }

    if (!criticalIncident.accountOwnerName) {
      setErrorMessage("Account Owner Name is required.");
      return;
    }

    if (!criticalIncident.accountNo) {
      setErrorMessage("Account Number is required.");
      return;
    }

    if (!criticalIncident.contactName) {
      setErrorMessage("Contact Name is required.");
      return;
    }

    if (!criticalIncident.contactRole) {
      setErrorMessage("Contact Role is required.");
      return;
    }

    if (!criticalIncident.contactEmail) {
      setErrorMessage("Contact Email is required.");
      return;
    }

    if (!criticalIncident.contactNo) {
      setErrorMessage("Contact Number is required.");
      return;
    }

    if (
      criticalIncident.contactNo.length < 8 ||
      criticalIncident.contactNo.length > 15
    ) {
      setErrorMessage("Contact Number length must be between 8 and 15 digits.");
      return;
    }

    if (!criticalIncident.preferredContact) {
      setErrorMessage("Preferred Contact is required.");
      return;
    }

    if (
      criticalIncident.preferredContact.length < 8 ||
      criticalIncident.preferredContact.length > 15
    ) {
      setErrorMessage("Preferred Contact length must be between 8 and 15 digits.");
      return;
    }

    if (!criticalIncident.issue) {
      setErrorMessage("Please describe the issue.");
      return;
    }

    try {

      setIsMessageSent(false);
      setErrorMessage("");

      setLoading(true);
      await createCriticalIncident(criticalIncident);

      setIsMessageSent(true);

      setCriticalIncident({
        ...criticalIncident,
        issueType: "System Outage",
        accountOwnerName: "",
        accountNo: "",
        contactName: "",
        contactRole: "",
        contactEmail: "",
        contactNo: "",
        preferredContact: "",
        issue: "",
        adminAccess: "Yes",
      });

    } catch (error) {
      // console.log(error.message);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePrefferedContactChange = (value) => {
    setCriticalIncident((prev) => ({
      ...prev,
      preferredContact: value,
    }));
  };

  return (
    <div>
      <div className="page container toupp-page p-5 my-5 animateSlideUp">
        <div>
          <h2>Critical Incident</h2>
          <hr />
          <p>
            A critical incident is defined as a Software Algo Solutions system
            outage or suspected fraud/account takeover. Please complete the form
            below only if you experience a critical incident outside of our
            standard operating hours (6:00 a.m. – 6:00 p.m. PT, Monday through
            Friday).
          </p>
          <p>
            {" "}
            <i>
              *PLEASE DO NOT SEND PERSONAL OR SENSITIVE INFORMATION VIA EMAIL.
            </i>
          </p>
        </div>

        <div className="form-container" style={{ margin: "0px auto 0px auto" }}>
          <form className="critical-incident-contact-form">
            <div className="form-group mb-3 w-100">
              <label
                for="issueType"
                className="mb-2 font-weight-bold text-left"
              >
                Issue Type{" "}
              </label>
              <input
                required
                disabled
                id="issueType"
                className="form-control"
                name="issueType"
                value={criticalIncident.issueType}
                onChange={handleChange}
                type="text"
              />
            </div>
            <div className="form-group mb-3 w-100">
              <label
                for="accountOwnerName"
                className="mb-2 font-weight-bold text-left"
              >
                Account Owner Name <span className="">*</span>
              </label>
              <input
                required
                id="accountOwnerName"
                className="form-control"
                name="accountOwnerName"
                value={criticalIncident.accountOwnerName}
                onChange={handleChange}
                type="name"
              />
            </div>
            <div className="form-group mb-3 w-100">
              <label
                for="accountNo"
                className="mb-2 font-weight-bold text-left"
              >
                Account No <span className="">*</span>
              </label>
              <input
                required
                id="accountNo"
                className="form-control"
                name="accountNo"
                value={criticalIncident.accountNo}
                onChange={handleChange}
                type="text"
              />
            </div>
            <div className="form-group mb-3 w-100">
              <label
                for="contactName"
                className="mb-2 font-weight-bold text-left"
              >
                Contact Name <span className="">*</span>
              </label>
              <input
                required
                id="contactName"
                className="form-control"
                name="contactName"
                value={criticalIncident.contactName}
                onChange={handleChange}
                type="name"
              />
            </div>
            <div className="form-group mb-3 w-100">
              <label
                for="contactRole"
                className="mb-2 font-weight-bold text-left"
              >
                Contact Role <span className="">*</span>
              </label>
              <input
                required
                id="contactRole"
                className="form-control"
                name="contactRole"
                value={criticalIncident.contactRole}
                onChange={handleChange}
                type="text"
              />
            </div>
            <div className="form-group mb-3 w-100">
              <label
                for="contactEmail"
                className="mb-2 font-weight-bold text-left"
              >
                Contact Email <span className="">*</span>
              </label>
              <input
                required
                id="contactEmail"
                className="form-control"
                name="contactEmail"
                value={criticalIncident.contactEmail}
                onChange={handleChange}
                type="email"
              />
            </div>
            <div>
              <div className="form-group mb-3 custom-react-phone">
                <label
                  for="contactNo"
                  className="mb-2 font-weight-bold  text-left"
                >
                  Contact No <span className="">*</span>
                </label>
                <div className=" react-tel-input ">
                  <PhoneInput
                    inputProps={{
                      name: "contactNo",
                      required: true,
                    }}
                    onChange={handleContactNoChange}
                    inputclassName="tel-class"
                    placeholder={"+1"}
                    country={"us"}
                    value={criticalIncident.contactNo}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 custom-react-phone">
              <label
                for="preferredContact"
                className="mb-2 font-weight-bold text-left"
              >
                Preferred Contact No <span className="">*</span>
              </label>
              <div className=" react-tel-input ">
                <PhoneInput
                  inputProps={{
                    name: "preferredContact",
                    required: true,
                  }}
                  onChange={handlePrefferedContactChange}
                  inputclassName="tel-class"
                  placeholder={"+1"}
                  country={"us"}
                  value={criticalIncident.preferredContact}
                />
              </div>
            </div>

            <div className="form-group mb-3">
              <label for="issue" className="mb-2 font-weight-bold text-left ">
                Please describe the issue <span className="">*</span>
              </label>
              <textarea
                required
                id="issue"
                className="form-control form-input-resize-none"
                rows="5"
                name="issue"
                value={criticalIncident.issue}
                onChange={handleChange}
                type="text"
              ></textarea>
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="sas-link"
                id="btnSend"
                style={{ margin: "0px auto" }}
                onClick={handleSubmit}
              >
                <span style={{ marginRight: "10px" }}>
                  {loading ? "Submitting..." : "Submit"}
                </span>{" "}
                &nbsp;
                <i
                  style={{ fontSize: "16px", marginRight: "0px" }}
                  className="fas fa-arrow-right"
                ></i>
              </button>
            </div>
            <div
              className={
                isMessageSent
                  ? "alert alert-success text-center"
                  : "alert alert-success text-center hidden"
              }
            >
              Critical Incident Sent!
            </div>
            <div
              className={
                errorMessage !== ""
                  ? "alert alert-danger"
                  : "alert alert-danger hidden"
              }
            >
              {errorMessage}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CriticalIncident;
