import React, { useEffect } from 'react';

function TermsOfUse(props) {
  useEffect(() => {
    props.setProgress(100);
    document.title = props.title || ""
  }, []);

  const currentDate = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString(undefined, options);

  return (
    <div>
      <div className="page container toupp-page p-5 my-5 animateSlideUp">
        <div>
          <h2>Terms of Use("Terms")</h2>
          <hr />
          <p>
            {' '}
            <i>Last updated: {formattedDate}</i>
          </p>
          <p>
            Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the{' '}
            <a href="https://wwww.softwarealgo.com/"> https://wwww.softwarealgo.com/ </a> operated by Software Algo
            Solutions.
          </p>
          <p>
            This Site provides online access to information about Company and our products, services and opportunities.{' '}
          </p>
          <p>
            Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms.
            These Terms apply to all visitors, users and others who access or use the Site.
          </p>
          <p>
            {' '}
            <b>
              {' '}
              BY ACCESSING OR USING THE SITE YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT, DO NOT USE THE
              SITE.{' '}
            </b>{' '}
          </p>
        </div>

        <div>
          <h4 className="mt-4"> Purchases</h4>
          <p>
            If you wish to purchase any our product or service ("Purchase"), you may send the request in one of the
            following ways:{' '}
          </p>
          <ul>
            <li>via Contact Form</li>
            <li>
              send the request to <a href="mailto:info@softwarealgo.com">info@softwarealgo.com</a>
            </li>
            <li>call by phone +1 (929) 269-2542.</li>
          </ul>
        </div>

        <div>
          <h4 className="mt-4"> Termination</h4>
          <p>
            We may terminate or suspend access to our Site immediately, without prior notice or liability, for any
            reason whatsoever, including without limitation if you breach the Terms.
          </p>
          <p>
            All provisions of the Terms which by their nature should survive termination shall survive termination,
            including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
            liability.
          </p>
        </div>

        <div>
          <h4 className="mt-4">Content, Copyrights and Trademarks</h4>
          <p>
            All text, material, data, and information, data files, description of our services or/and our products,
            graphics, images, user interfaces, visual interfaces, photographs, trademarks, logos and computer code etc.
            ("Content"), contained on the website is owned, controlled or licensed by or to Software Algo Solutions, and
            is protected by applicable intellectual property and other laws, including trademark and copyright laws.
            Company owns and retains all copyrights in the Content.
          </p>
          <p>
            Except as expressly provided in these Terms, no part of the Site and no Content may be copied, reproduced,
            modified, published, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or
            distributed in any way (including "mirroring") to any other computer, server, Web site or other medium for
            publication or distribution or for any commercial enterprise, without Company’s express prior written
            consent.
          </p>
          <p>
            Company’s logos and trademarks are trademarks and the property of Company. The appearance, layout, color
            scheme, and design of the Site are protected trade dress. You do not receive any right or license to use the
            foregoing. We may use and incorporate into the Site any suggestions or other feedback you provide, without
            payment or condition.
          </p>
        </div>

        <div>
          <h4 className="mt-4">Prohibited Use of the Site</h4>
          <p>By accessing the Site, you agree that you will not: </p>
          <ul>
            <li>Use the Site in violation of these Terms;</li>
            <li>
              Copy, modify, create a derivative work from, reverse engineer or reverse assemble the Site, or otherwise
              attempt to discover any source code, or allow any third party to do so;
            </li>
            <li>
              Sell, assign, sublicense, distribute, commercially exploit, grant a security interest in or otherwise
              transfer any right in, or make available to a third party, the Content or Site in any way;
            </li>
            <li>Mirror or frame the Site or any part of it on any other web site or web page;</li>
            <li>Attempt to gain unauthorized access to the Site;</li>
            <li>
              Probe, scan or test the vulnerability of the Site or any network connected to the Site, nor breach the
              security or authentication measures on the Site or any network connected to the Site;
            </li>
            <li>
              Take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the
              Site or any systems or networks connected to the Site;
            </li>
            <li>
              Use any device, software or routine to interfere or attempt to interfere with the proper working of the
              Site.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-4"> Privacy Policy</h4>
          <p>
            By using the Software Algo Solutions site, you accept the terms of Privacy Policy which is an integral part
            of these Terms.
          </p>
          <p>
            Please check our Privacy Policy to learn more
            <a href="/index.html#/privacyPolicy"> https://www.softwarealgo.com/about/privacy-policy </a>
          </p>
        </div>

        <div>
          <h4 className="mt-4"> Changes</h4>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time without giving
            you prior notice. Your use of the Site following any such modification constitutes your acceptance to follow
            and be bound by these Terms as modified. The last date these Terms were revised is set forth above.
          </p>
        </div>

        <div>
          <h4 className="mt-4"> Contact Us</h4>
          <p>
            If you have any questions about these Terms, please contact us by email
            <a href="mailto:info@softwarealgo.com">info@softwarealgo.com</a> or by phone +1 (929) 269-2542.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
