import React, { useEffect } from 'react';

function PrivacyPolicy(props) {
  useEffect(() => {
    props.setProgress(100);
    document.title = props.title || ""
  }, []);

  const currentDate = new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const year = currentDate.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  return (
    <div>
      <div className="page container toupp-page p-5 my-5 animateSlideUp">
        <div>
          <h2>Privacy Policy</h2>
          <hr />
          <p>
            This privacy statement explains what personal data Software Algo Solutions collects from you and how we use
            that data.
          </p>
          <p>
            {' '}
            <i>Last updated on {formattedDate}.</i>
          </p>
        </div>

        <div>
          <h4 className="mt-4">What Personal Data We Collect</h4>
          <p>
            Software Algo Solutions collects data to provide you with direct feedback in respect of our services. You
            provide some of this data directly, such as when you fill in the Contact us form to contact us for support
            or comment on our services.
          </p>
          <p>The data we collect can include the following:</p>
          <p>
            <b> Name and contact data. </b> We collect your first and last name, work email address, phone number,
            company name and other similar contact data.
          </p>
          <p>
            <b> Demographic data. </b> We collect data about you such as your country and preferred language.
          </p>
          <p>
            <b> Website data. </b> We collect data about how you interact within Software Algo Solutions website. For
            example, we collect:
          </p>
          <ul>
            <li>
              Configuration data. We collect data about the network you use to connect to our website. It includes your
              IP address.
            </li>
            <li>
              Error reports and performance data. We collect data about problems you experience with our services. This
              data helps us to diagnose problems and to provide solutions. Depending on your browsing environment and
              settings, error reports can include such data as the type or the severity of the problem, details of the
              software or the hardware related to the error, the contents of the files you were using when the error
              occurred, as well as the data about other software on your device.
            </li>
            <li>
              Troubleshooting and Help Data. In case Software Algo Solutions is engaged in troubleshooting and help, we
              collect data about you and your hardware, software, and other details related to the incident. Such data
              includes the contact data, the content of your chats and other communications with Software Algo
              Solutions, the data about the condition of the machine and the application when the fault occurred and
              during diagnostics, and the system and registry data about software installations and hardware
              configurations.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-4">Why We Collect Personal Data</h4>
          <p>
            Software Algo Solutions collects the data for the following purposes: sending communications, including
            promotional communications; technical troubleshooting; improving website user experience.
          </p>
          <p>
            In carrying out these purposes, we intend to tell you about issues you’ve asked us to tell you about; to
            contact you if we need to obtain or provide additional information; to make sure our records are right and
            to check every now and then that you’re happy and satisfied. We don't rent or trade email lists with other
            organisations and businesses.
          </p>
          <p>
            However, to enhance privacy, we have built in technological and procedural safeguards designed to prevent
            data leaks. For example, we store data we collect from you indirectly or directly, such as your name, email
            address or phone number, company name, in a secure database.
          </p>
        </div>

        <div>
          <h4 className="mt-4">Notice to End Users</h4>
          <p>
            Software Algo Solutions is intended to deliver its services  to organizations. Your use of Software Algo
            Solutions’s website may be subject to your organization's policies, if any. If your organization is
            administering your use of Software Algo Solutions’s website, please direct your privacy inquiries to your
            administrator. Software Algo Solutions is not responsible for the privacy or security practices of our
            customers, which may differ from those set forth in this privacy statement.  
          </p>
          <p>
            If you use an email address provided by an organization you are affiliated with, such as an employer or
            school, the owner of the domain (e.g., your employer) associated with your email address may access and
            process your data, including the contents of your communications and files.
          </p>
        </div>

        <div>
          <h4 className="mt-4">For How Long the Data Will Be Stored</h4>
          <p>
            Software Algo Solutions retains personal data for as long as necessary to provide the services and the
            feedback you have requested, or for other essential purposes, such as complying with our legal obligations,
            enforcing our agreements. Because these needs can vary for different data types, actual retention periods
            may vary. The criteria used to determine the retention periods include:
          </p>
          <ul>
            <li>
              <i>Is the personal data of a sensitive type? </i> If so, a shortened retention time would generally be
              appropriate, which amounts to up to five years
            </li>
            <li>
              <i>
                Is Software Algo Solutions subject to a legal, contractual, or similar obligation to retain the data?{' '}
              </i>{' '}
              Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to
              preserve data relevant to an investigation, or data that must be retained for the purposes of litigation.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="mt-4">How We Change this Privacy Policy</h4>
          <p>
            We will update this privacy statement when necessary to amend its terms or reflect customer feedback. When
            we post changes to this statement, we will revise the "last updated" date at the top of the statement. If
            there are material changes to the statement, we will notify you either by prominently posting a notice of
            such changes before they take effect or by directly sending you a notification.
          </p>
        </div>

        <div>
          <h4 className="mt-4">How to Contact Us</h4>
          <p>
            If you have a privacy concern, complaint, or question, please email us at 
            <a href="mailto:info@softwarealgo.com">info@softwarealgo.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
