import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { client } from '../../../../constants/sas_links';

function Security(props) {
    // Service Header Section Screen States
    const [isOnServiceHeaderScreen, setIsOnServiceHeaderScreen] = useState(false);
    const [serviceH1Class, setServiceH1Class] = useState('');

    const handleWayPointEnterServiceHeaderSection = () => {
        setIsOnServiceHeaderScreen(true);
        setTimeout(() => {
            setServiceH1Class('animateSlideUp');
        }, 500);
    };

    // Service List Section Screen States
    const [isOnServiceListScreen, setIsOnServiceListScreen] = useState(false);
    const [supportClass, setSupportClass] = useState('');
    const [deploymentClass, setDeploymentClass] = useState('');
    const [integrationClass, setIntegrationClass] = useState('');
    const [documentationClass, setDocumentationClass] = useState('');
    const [customizationClass, setCustomizationClass] = useState('');

    const handleWayPointEnterServiceListSection = () => {
        setIsOnServiceListScreen(true);
        setTimeout(() => {
            setSupportClass('animateSlideUp');
        }, 0);
        setTimeout(() => {
            setDeploymentClass('animateSlideUp');
        }, 300);
        setTimeout(() => {
            setIntegrationClass('animateSlideUp');
        }, 600);
        setTimeout(() => {
            setDocumentationClass('animateSlideUp');
        }, 900);
        setTimeout(() => {
            setCustomizationClass('animateSlideUp');
        }, 1200);
    };

    return (
        <div ref={props.thisRef}>
            {/* <!-- Services Section --> */}
            <div id="security-section" className="service-content">
                <Waypoint onEnter={handleWayPointEnterServiceHeaderSection}>
                    <div id="contact-section" className="service-section">
                        <div
                            id="service-h1"
                            className={isOnServiceHeaderScreen ? 'service-container ' + serviceH1Class : 'service-container'}
                        >
                            <div className="service-heading">

                                <div>
                                    <center>
                                        <h2>COMMITMENT TO SECURITY</h2>
                                    </center>
                                    <h3>Purpose</h3>
                                    <p style={{ color: '#000' }}>
                                        To provide SAS Clients and prospective clients with a complete objective description of our system’s bounding lines and security commitments.
                                    </p>

                                    <h3>Policies</h3>
                                    <p style={{ color: '#000' }}>
                                        SAS accepts the following principles to ensure the implementation of Security requirements throughout the organization.
                                    </p>
                                    <ul>

                                        <li>
                                            Embrace compliance to the regulatory framework as a standard and reinforce corporate information security posture by adopting respected professional norms and standards.
                                        </li>
                                        <li>
                                            To adopt a risk-focused approach and establish awareness in identification threats, weakness remediation, and classification of information assets where confidentiality, integrity, and availability of client, employee, shareholder, and corporate data is at stake.
                                        </li>
                                        <li>
                                            Apply all necessary tools and resources to enable and encourage Security Risk Aware culture throughout the entire organization and provide security training.
                                        </li>
                                        <li>
                                            Enforce Security standards when establishing and maintaining business relationships and partnerships with external parties.
                                        </li>
                                        <li>
                                            Maintains a Data Back-up Plan which creates and maintains retrievable copies of data within application level and storage device replication.
                                        </li>
                                        <li>
                                            Maintains a disaster recovery plan for recovery in the event of failure or disaster including all critical elements of the applications, snapshot technology in the event of significant data corruption, backup databases for production data, and an alternate site if the primary site goes down.
                                        </li>
                                    </ul>
                                    <p style={{ color: '#000' }}>
                                        This policy applies to all employees of Software Algo Solutions. Violation of this policy could result in disciplinary action leading up to and including termination of employment and/or legal action.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Waypoint>


            </div>
        </div>
    );
}

export default Security;
