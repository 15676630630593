import { ContactUs, ContactUsServiceClient } from '../proto/admpb/contactus_grpc_web_pb';

const service = new ContactUsServiceClient(window.env.GRPC_ENDPOINT, {});

export async function contactSAS(param) {
  return new Promise((resolve, reject) => {
    const req = new ContactUs();
    req.setName(param.name);
    req.setEmail(param.email);
    req.setPhone(param.phone);
    req.setCompanyName(param.companyName);
    req.setCountry(param.country);
    req.setNote(param.note);

    service.createContactUs(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
